var currentUrl = window.location.pathname;
var indexUrl = "/";
var servicesUrl = "/services";
var postUrl = "/article";
var contactUrl = "/contact";
var agendaUrl = "/agenda";
var appointmentUrl = "/prendre-rendez-vous";

if(currentUrl === indexUrl){
    var offsetFix = 73;

    if (screen.width >= 768) {
        offsetFix = 77;
    }

    if (screen.width >= 1200) {
        offsetFix = 80;
    }

    AOS.init();
        
    $(document).ready(function(){
        $("#testimonial-slider").owlCarousel({
            autoplay:true,
            responsiveClass:true,
            dots:false,
            rewind:true,
            responsive:{
                0:{
                    items:1,
                },
                992:{
                    items:2,
                },
                1400:{
                    items:3,
                }
            }
        });

        $("#values-slider").owlCarousel({
            responsiveClass:true,
            responsive:{
                0:{
                    rewind:true,
                    autoplay:true,
                    items:1,
                },
                768:{
                    rewind:true,
                    autoplay:true,
                    items:2,
                },
                1200:{
                    rewind:true,
                    autoplay:true,
                    items:3,
                },
                1400:{
                    mouseDrag:false,
                    touchDrag:false,
                    pullDrag:false,
                    items:4,
                }
            }
        });
    });

    setTimeout(() => {
        var currentUrl = window.location.href;
        var currentHash = currentUrl.split('#').pop();
        history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);

        if (currentHash == "section-a-propos") {
            $('#indexAbout').trigger('click');
        }
    }, 50);

    $('#indexAbout').on('click', function(event){
        var offsetClick = $('#section-a-propos').offset().top - offsetFix;
                
        event.preventDefault();
        $('html, body').animate({
            'scrollTop':offsetClick
        }, 300)
    });
}

if(currentUrl === indexUrl || currentUrl === contactUrl){
    $(document).ready(function(){
        if($('#map-activate-js').val() == 1) {
            initMap();
        }
    });

    var contacts = JSON.parse($('#contacts-js').val());
    var choice = $('#map-type-js').val();
    var markerStyle = $('#marker-js').val();
    var carte = null;
    var marker = null;
    var url = null;
    var locations = [];
    var latitudes = [];
    var rootUrl = window.location.origin;

    switch (markerStyle) {
        case "1":
            url = rootUrl + "/images/leaflet/marker-icon-1.webp";
        break;
        case "2":
            url = rootUrl + "/images/leaflet/marker-icon-2.webp";
        break;
        case "3":
            url = rootUrl + "/images/leaflet/marker-icon-3.webp";
        break;
        case "4":
            url = rootUrl + "/images/leaflet/marker-icon-4.webp";
        break;
    }

    function style(feature) {
        return {
            weight: 0.5,
            opacity: 0.5,
            color: '#3388ff',
            dashArray: '3',
            fillColor: '#3388ff',
            fillOpacity: 0.2
        };
    }

    function initMap(){
        carte = L.map('map', {scrollWheelZoom: false, dragging: window.matchMedia('(min-width: 768px)').matches, zoomSnap: 0.5, zoomDelta: 0.5});

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        {attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors', minZoom: 3, maxZoom: 16}).addTo(carte);

        if(choice == "no_zone") {
            $.each(contacts, function(key, contact) {
                const location = contact.location;
                const locationSplit = location.split(', ')
                const lat = parseFloat(locationSplit[0]);
                const lon = parseFloat(locationSplit[1]);
                const myIcon = L.icon({
                    iconUrl: url,
                    iconSize:    [40, 40],
                    iconAnchor:  [20, 40],
                    popupAnchor: [0, -35],
                });

                locations.push([lat, lon]);
                latitudes.push(lat);
                marker = L.marker([lat, lon], {icon: myIcon}).addTo(carte);
                
                var phoneNumber = "";
                var mail = "";
                if(contact.phone_number != null){phoneNumber = '<div class="mt-1"><span class="fw-bold">Tel : </span>' + contact.phone_number + '</div>'};
                if(contact.mail != null){mail = '<div class="mt-1"><span class="fw-bold">E-mail : </span>' + contact.mail + '</div>'};

                marker.bindPopup(
                    '<div class="text-center"><div class="fw-bold">' + contact.name +
                    '</div><div class="mt-1">' + contact.address + ' ' + contact.zip_code + ' ' + contact.city +
                    '</div>'+ phoneNumber + mail + '</div>'
                );
            });

            carte.fitBounds(locations);

            var minLat = Math.min(...latitudes);
            var maxLat = Math.max(...latitudes);
            var distanceM = carte.distance(L.latLng(minLat, 1.5), L.latLng(maxLat, 1.5));
            var distanceKm = distanceM / 1000;

            if(distanceKm == 0 ){
                carte.zoomOut(7);
            }

            if(distanceKm > 0 && distanceKm <= 25 ){
                carte.zoomOut(1);
            }

            if(distanceKm > 25){
                carte.zoomOut(0.5);
            }
        }

        if(choice == 'radius'){
            $.each(contacts, function(key, contact){
                const location = contact.location;
                const locationSplit = location.split(', ')
                const lat = parseFloat(locationSplit[0]);
                const lon = parseFloat(locationSplit[1]);

                const myIcon = L.icon({
                    iconUrl: url,
                    iconSize:    [40, 40],
                    iconAnchor:  [20, 40],
                    popupAnchor: [0, -35],
                });

                if(contact.is_radius == "oui"){
                    var radius = contact.radius * 1000;
                    const circle = L.circle([lat, lon], radius, {
                        weight: 1,
                        color: '#3388ff',
                        opacity: 0.5,
                        fillColor: '#3388ff',
                        fillOpacity: 0.2
                    }).addTo(carte);
                }

                locations.push([lat, lon]);
                latitudes.push(lat);
                marker = L.marker([lat, lon], {icon: myIcon}).addTo(carte);

                var phoneNumber = "";
                var mail = "";
                if(contact.phone_number != null){phoneNumber = '<div class="mt-1"><span class="fw-bold">Tel : </span>' + contact.phone_number + '</div>'};
                if(contact.mail != null){mail = '<div class="mt-1"><span class="fw-bold">E-mail : </span>' + contact.mail + '</div>'};

                marker.bindPopup(
                    '<div class="text-center"><div class="fw-bold">' + contact.name +
                    '</div><div class="mt-1">' + contact.address + ' ' + contact.zip_code + ' ' + contact.city +
                    '</div>'+ phoneNumber + mail + '</div>'
                );
            });

            carte.fitBounds(locations);

            var minLat = Math.min(...latitudes);
            var maxLat = Math.max(...latitudes);
            var distanceM = carte.distance(L.latLng(minLat, 1.5), L.latLng(maxLat, 1.5));
            var distanceKm = distanceM / 1000;

            if(distanceKm == 0 ){
                carte.zoomOut(7);
            }

            if(distanceKm > 0 && distanceKm <= 25 ){
                carte.zoomOut(1);
            }

            if(distanceKm > 25){
                carte.zoomOut(0.5);
            }
        }

        if(choice == 'france'){
            $.each(contacts, function(key, contact){
                const location = contact.location;
                const locationSplit = location.split(', ')
                const lat = parseFloat(locationSplit[0]);
                const lon = parseFloat(locationSplit[1]);

                const myIcon = L.icon({
                    iconUrl: url,
                    iconSize:    [40, 40],
                    iconAnchor:  [20, 40],
                    popupAnchor: [0, -35],
                });

                locations.push([lat, lon]);
                latitudes.push(lat);
                marker = L.marker([lat, lon], {icon: myIcon}).addTo(carte);

                var phoneNumber = "";
                var mail = "";
                if(contact.phone_number != null){phoneNumber = '<div class="mt-1"><span class="fw-bold">Tel : </span>' + contact.phone_number + '</div>'};
                if(contact.mail != null){mail = '<div class="mt-1"><span class="fw-bold">E-mail : </span>' + contact.mail + '</div>'};

                marker.bindPopup(
                    '<div class="text-center"><div class="fw-bold">' + contact.name +
                    '</div><div class="mt-1">' + contact.address + ' ' + contact.zip_code + ' ' + contact.city +
                    '</div>'+ phoneNumber + mail + '</div>'
                );
            });

            L.geoJson(dataFrance, {
                    style: style,
            }).addTo(carte);

            carte.setView([47, 3], 5);
        }

        if(choice == 'europe'){
            $.each(contacts, function(key, contact){
                const location = contact.location;
                const locationSplit = location.split(', ')
                const lat = parseFloat(locationSplit[0]);
                const lon = parseFloat(locationSplit[1]);

                const myIcon = L.icon({
                    iconUrl: url,
                    iconSize:    [40, 40],
                    iconAnchor:  [20, 40],
                    popupAnchor: [0, -35],
                });

                locations.push([lat, lon]);
                latitudes.push(lat);
                marker = L.marker([lat, lon], {icon: myIcon}).addTo(carte);
                
                var phoneNumber = "";
                var mail = "";
                if(contact.phone_number != null){phoneNumber = '<div class="mt-1"><span class="fw-bold">Tel : </span>' + contact.phone_number + '</div>'};
                if(contact.mail != null){mail = '<div class="mt-1"><span class="fw-bold">E-mail : </span>' + contact.mail + '</div>'};

                marker.bindPopup(
                    '<div class="text-center"><div class="fw-bold">' + contact.name +
                    '</div><div class="mt-1">' + contact.address + ' ' + contact.zip_code + ' ' + contact.city +
                    '</div>'+ phoneNumber + mail + '</div>'
                );
            });

            L.geoJson(dataEurope, {
                style: style,
            }).addTo(carte);

            carte.setView([55, 14], 3);
        }
    }
}

if(currentUrl === servicesUrl){
    $('.service').on("click", function() {
        if(screen.width < 1200) {
            $('#service-desc-'+this.id).toggleClass('opacity-100');
            $('#service-title-'+this.id).toggleClass('opacity-0');
            $('#service-after-'+this.id).toggleClass('bg-transparent');
        }
    })
}

if(currentUrl.includes(postUrl)){
    $(".post img").click(function(){
        $('#modal-width').css("maxWidth", 966);
        $('#modal-src').attr('src', $(this).attr("src"));
        $('#modal-src').attr('data-src', $(this).attr("src"));
        $('#modal-src').attr('width', 966);
        $('#modal-src').attr('height', $(this)[0].naturalHeight);
        $('#modal-img').modal('show');
    });
}

if(currentUrl === contactUrl || currentUrl.includes(appointmentUrl)){
    $(function() {
        var firstInvalidField = $('.is-invalid');

        if(firstInvalidField.length){
            var firstInvalidFieldPosition = firstInvalidField.offset().top;
            $('html, body').animate({
                scrollTop: firstInvalidFieldPosition - ($('header').height() * 2)
            }, 300);
        }
    });
}

if(currentUrl === contactUrl){
    $(".modal-close").on("click", function () {
        $('#confirmModal').removeClass('show d-block');
        $('.modal-backdrop').addClass('d-none');
    });
}

if(currentUrl === agendaUrl){
    $(".modal-close").on("click", function () {
        $('#rdvModal').removeClass('show d-block');
        $('.modal-backdrop').addClass('d-none');
    });

    var noSpamPrev = false; 
    var noSpamNext = false; 
    $('.prevAgendaMonth').on('click', function(){
        if(noSpamPrev == false){
            $('#prevAgenda').click();
        }

        if(noSpamPrev == true){
            return;
        }

        $('.prevAgendaMonth').attr('data-bs-target', '');

        noSpamPrev = true;

        setTimeout(() => {
            noSpamPrev = false;
            $('.prevAgendaMonth').attr('data-bs-target', '#agendaMonths');

        }, 800);
    });

    $('.nextAgendaMonth').on('click', function(){
        if(noSpamNext == false){
            $('#nextAgenda').click();
        }

        if(noSpamNext == true){
            return;
        }

        $('.nextAgendaMonth').attr('data-bs-target', '');

        noSpamNext = true;

        setTimeout(() => {
            noSpamNext = false;
            $('.nextAgendaMonth').attr('data-bs-target', '#agendaMonths');

        }, 800);
    });
}

$(window).scroll(function () {
    if ($(this).scrollTop() > 320) {
        $('#top-header').addClass('h-0');
    } else {
        $('#top-header').removeClass('h-0');
    }
    
    if ($(this).scrollTop() > 550) {
        $('#scroll-top').fadeIn();
    } else {
        $('#scroll-top').fadeOut();
        }
    });
    
    $('#scroll-top').click(function () {
    $('body,html').animate({
        scrollTop: 0
    }, 300);
    return false;
});